define("ember-svg-jar/inlined/ibm-machine-learning-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M27 24a2.962 2.962 0 00-1.285.3l-4.3-4.3H18v2h2.586l3.715 3.715c-.196.4-.299.84-.301 1.285a3 3 0 103-3zm0 4a1 1 0 110-2 1 1 0 010 2zm0-15a2.995 2.995 0 00-2.816 2H18v2h6.184A2.995 2.995 0 1027 13zm0 4a1 1 0 110-2 1 1 0 010 2zm0-15a3.003 3.003 0 00-3 3c.003.48.123.95.348 1.373L20.596 10H18v2h3.404l4.4-4.252A2.999 2.999 0 1027 2zm0 4a1 1 0 110-2 1 1 0 010 2z\"/><path fill=\"currentColor\" d=\"M18 6h2V4h-2a3.976 3.976 0 00-3 1.382A3.976 3.976 0 0012 4h-1a9.01 9.01 0 00-9 9v6a9.01 9.01 0 009 9h1a3.976 3.976 0 003-1.382A3.976 3.976 0 0018 28h2v-2h-2a2.002 2.002 0 01-2-2V8a2.002 2.002 0 012-2zm-6 20h-1a7.004 7.004 0 01-6.92-6H6v-2H4v-4h3a3.003 3.003 0 003-3V9H8v2a1 1 0 01-1 1H4.08A7.005 7.005 0 0111 6h1a2.002 2.002 0 012 2v4h-2v2h2v4h-2a3.004 3.004 0 00-3 3v2h2v-2a1 1 0 011-1h2v4a2.002 2.002 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 32 32"
    }
  };
});