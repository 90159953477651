define("ember-svg-jar/inlined/font-awesome-list-ol-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.86 25.063l1.094-1.26c.218-.243.332-.561.317-.887v-.207c0-.459-.24-.709-.708-.709H1a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1.427a9.849 9.849 0 00-.688.77l-.35.437c-.25.317-.328.633-.175.93l.065.12c.188.36.394.493.766.493h.296c.645 0 .996.152.996.568 0 .295-.263.514-.898.514a2.596 2.596 0 01-.967-.195c-.405-.243-.733-.219-.974.195l-.35.582c-.232.383-.2.732.165.996.481.293 1.273.59 2.312.59 2.135 0 3.031-1.422 3.031-2.758-.002-.898-.57-1.86-1.795-2.18zM31 14H11a1 1 0 00-1 1v2a1 1 0 001 1h20a1 1 0 001-1v-2a1 1 0 00-1-1zm0-10H11a1 1 0 00-1 1v2a1 1 0 001 1h20a1 1 0 001-1V5a1 1 0 00-1-1zm0 20H11a1 1 0 00-1 1v2a1 1 0 001 1h20a1 1 0 001-1v-2a1 1 0 00-1-1zM1 10h4a.5.5 0 00.5-.5v-1A.5.5 0 005 8H4V2.5a.5.5 0 00-.5-.5H2a.5.5 0 00-.446.276l-.5 1A.5.5 0 001.5 4H2v4H1a.5.5 0 00-.5.5v1a.5.5 0 00.5.5zM.756 20H5a.5.5 0 00.5-.5v-1A.5.5 0 005 18H2.583c.205-.643 3.02-1.168 3.02-3.527C5.604 12.655 4.042 12 2.825 12c-1.335 0-2.112.625-2.528 1.172-.273.35-.188.677.175.96l.536.43c.35.286.687.155 1.007-.152a.84.84 0 01.592-.24c.208 0 .58.098.58.547.002.795-3.186 1.365-3.186 4.32v.25c0 .463.318.713.756.713z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});