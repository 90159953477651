define("ember-svg-jar/inlined/font-awesome-sync-alt-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23.17 8.33a10.426 10.426 0 00-7.18-2.83c-4.84.004-9.02 3.324-10.174 7.928a.752.752 0 01-.728.572H1.506a.75.75 0 01-.738-.886C2.122 5.933 8.427.5 16 .5a15.45 15.45 0 0110.707 4.293L28.94 2.56c.945-.945 2.561-.276 2.561 1.06V12a1.5 1.5 0 01-1.5 1.5h-8.379c-1.336 0-2.005-1.616-1.06-2.56l2.609-2.61zM2 18.5h8.379c1.336 0 2.005 1.616 1.06 2.56L8.83 23.67a10.426 10.426 0 007.18 2.83c4.838-.004 9.02-3.321 10.174-7.928a.752.752 0 01.728-.572h3.582a.75.75 0 01.738.886C29.879 26.067 23.574 31.5 16 31.5a15.448 15.448 0 01-10.707-4.293L3.06 29.44c-.944.944-2.56.275-2.56-1.06V20A1.5 1.5 0 012 18.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});