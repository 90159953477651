define("ember-svg-jar/inlined/font-awesome-lock-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M27 14h-1.5V9.5C25.5 4.263 21.238 0 16 0S6.5 4.263 6.5 9.5V14H5a3 3 0 00-3 3v12a3 3 0 003 3h22a3 3 0 003-3V17a3 3 0 00-3-3zm-6.5 0h-9V9.5C11.5 7.019 13.519 5 16 5s4.5 2.019 4.5 4.5V14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});