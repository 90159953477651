define("ember-svg-jar/inlined/ibm-print", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M28 9h-3V3H7v6H4a2 2 0 00-2 2v10a2 2 0 002 2h3v6h18v-6h3a2 2 0 002-2V11a2 2 0 00-2-2zM9 5h14v4H9V5zm14 22H9V17h14v10zm5-6h-3v-6H7v6H4V11h24v10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});