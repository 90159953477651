define("ember-svg-jar/inlined/font-awesome-font-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M29 26h-1.463l-8.17-22.644A2 2 0 0017.474 2h-2.948a2 2 0 00-1.893 1.356L4.463 26H3a1 1 0 00-1 1v2a1 1 0 001 1h8a1 1 0 001-1v-2a1 1 0 00-1-1H9.776l1.457-4h9.534l1.457 4H21a1 1 0 00-1 1v2a1 1 0 001 1h8a1 1 0 001-1v-2a1 1 0 00-1-1zm-15.947-9L16 8.907 18.947 17h-5.894z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});