define("ember-svg-jar/inlined/ibm-document-blank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M25.7 9.3l-7-7A.908.908 0 0018 2H8a2.006 2.006 0 00-2 2v24a2.006 2.006 0 002 2h16a2.005 2.005 0 002-2V10a.907.907 0 00-.3-.7zM18 4.4l5.6 5.6H18V4.4zM24 28H8V4h8v6a2.006 2.006 0 002 2h6v16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});