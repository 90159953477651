define("ember-svg-jar/inlined/font-awesome-underline-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4 4h2v10c0 5.514 4.486 10 10 10s10-4.486 10-10V4h2a1 1 0 001-1V1a1 1 0 00-1-1h-9a1 1 0 00-1 1v2a1 1 0 001 1h2v10a5 5 0 11-10 0V4h2a1 1 0 001-1V1a1 1 0 00-1-1H4a1 1 0 00-1 1v2a1 1 0 001 1zm25 24H3a1 1 0 00-1 1v2a1 1 0 001 1h26a1 1 0 001-1v-2a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});