define("ember-svg-jar/inlined/ibm-certificate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24 17l1.912 3.703 4.088.594L27 24l.771 4L24 25.75 20.229 28 21 24l-3-2.703 4.2-.594L24 17zM6 16h6v2H6zm0-4h10v2H6zm0-4h10v2H6z\"/><path d=\"M16 26H4V6h24v10h2V6a2 2 0 00-2-2H4a2 2 0 00-2 2v20a2 2 0 002 2h12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32",
      "fill": "currentColor"
    }
  };
});