define("ember-svg-jar/inlined/ibm-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20 21h-8a2 2 0 01-2-2v-2a2 2 0 012-2h8a2 2 0 012 2v2a2 2 0 01-2 2zm-8-4v2h8v-2z\"/><path d=\"M28 4H4a2 2 0 00-2 2v4a2 2 0 002 2v16a2 2 0 002 2h20a2 2 0 002-2V12a2 2 0 002-2V6a2 2 0 00-2-2zm-2 24H6V12h20zm2-18H4V6h24v4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32",
      "fill": "currentColor"
    }
  };
});