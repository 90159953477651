define("ember-svg-jar/inlined/font-awesome-link-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20.413 11.586a9.495 9.495 0 01.022 13.412l-.022.023-4.2 4.2c-3.705 3.705-9.731 3.704-13.435 0-3.705-3.703-3.705-9.73 0-13.434l2.319-2.32c.615-.615 1.674-.206 1.706.663.04 1.108.239 2.22.605 3.295.125.364.036.767-.236 1.038l-.818.819c-1.752 1.751-1.807 4.603-.072 6.372a4.505 4.505 0 006.395.032l4.2-4.2a4.499 4.499 0 00-.646-6.9 1.003 1.003 0 01-.434-.787 2.49 2.49 0 01.73-1.863l1.317-1.316a1.004 1.004 0 011.286-.108c.458.32.888.68 1.283 1.074zm8.808-8.808c-3.704-3.704-9.73-3.705-13.435 0l-4.2 4.2a.807.807 0 00-.022.023c-3.66 3.68-3.712 9.673.022 13.412.395.395.825.754 1.283 1.074.4.28.941.237 1.286-.108l1.316-1.316a2.49 2.49 0 00.731-1.863 1.004 1.004 0 00-.434-.787 4.499 4.499 0 01-.646-6.9l4.2-4.2a4.505 4.505 0 016.395.032c1.735 1.769 1.68 4.62-.072 6.372l-.818.819c-.272.271-.36.674-.237 1.038.367 1.074.566 2.187.606 3.295.032.869 1.091 1.278 1.706.663l2.32-2.32c3.704-3.703 3.704-9.73 0-13.434z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});