define("ember-svg-jar/inlined/unfavorite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M18.67 11.92L16 6.52l-2.8 5.53-.46 1-1 .15-6.16.89L10 18.44l.74.77-.14.78-2.392 2.391.432-2.511-7.36-7.18 10.17-1.47L16 2l4.16 8.43-1.49 1.49zm-8.625 11.445L9.56 26.34l5.51-2.85L16 23l.97.41 5.51 2.89-1.05-6.13-.18-1 .75-.73 4.38-4.3-6.16-.89-.053-.008 1.813-1.814 8.74 1.272-7.36 7.17L25.1 30 16 25.22 6.9 30l.723-4.213L10 23.41l.045-.045z\" clip-rule=\"evenodd\"/><path fill=\"currentColor\" d=\"M29.79 3.2l1.41 1.41s-16.442 16.439-20 20L4.61 31.2 3.2 29.79 29.79 3.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 32 32"
    }
  };
});