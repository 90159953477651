define("ember-svg-jar/inlined/ibm-mail-reply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M25 18l-1.414 1.414L26.167 22H18a4 4 0 000 8h2v-2h-2a2 2 0 010-4h8.167l-2.583 2.587L25 28l5-5z\"/><path d=\"M10 22H4L3.997 6.906l11.434 7.916a1 1 0 001.138 0L28 6.91V16h2V6a2.002 2.002 0 00-2-2H4a2 2 0 00-2 1.997V22a2.003 2.003 0 002 2h6zM25.799 6L16 12.784 6.201 6z\"/><path d=\"M0 0h32v32H0z\" fill=\"none\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});