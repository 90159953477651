define("ember-svg-jar/inlined/font-awesome-sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.504 18.44H23.38c1.338 0 2.006 1.62 1.063 2.563l-7.438 7.438a1.494 1.494 0 01-2.119 0l-7.443-7.438c-.944-.944-.275-2.562 1.062-2.562zm15.938-6.562l-7.438-7.437a1.494 1.494 0 00-2.119 0l-7.443 7.437c-.944.944-.275 2.563 1.062 2.563H23.38c1.338 0 2.006-1.62 1.063-2.563z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});