define("ember-svg-jar/inlined/font-awesome-bars-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 8.25h26a1 1 0 001-1v-2.5a1 1 0 00-1-1H3a1 1 0 00-1 1v2.5a1 1 0 001 1zm0 10h26a1 1 0 001-1v-2.5a1 1 0 00-1-1H3a1 1 0 00-1 1v2.5a1 1 0 001 1zm0 10h26a1 1 0 001-1v-2.5a1 1 0 00-1-1H3a1 1 0 00-1 1v2.5a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});