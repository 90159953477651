define("ember-svg-jar/inlined/font-awesome-caret-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.956 12h16.082c1.112 0 1.668 1.344.88 2.131l-8.037 8.044a1.252 1.252 0 01-1.768 0l-8.038-8.044C6.287 13.344 6.844 12 7.956 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});