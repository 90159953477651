define("ember-svg-jar/inlined/font-awesome-caret-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11 24.331V8.252c0-1.113 1.344-1.67 2.131-.882l8.044 8.037a1.252 1.252 0 010 1.77l-8.044 8.037C12.344 26 11 25.444 11 24.33z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});