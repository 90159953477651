define("ember-svg-jar/inlined/font-awesome-angle-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.267 22.363l-8.503-8.426a1.471 1.471 0 010-2.1l1.413-1.4a1.504 1.504 0 012.12 0l6.027 5.972 6.027-5.972a1.504 1.504 0 012.119 0l1.413 1.4a1.471 1.471 0 010 2.1l-8.503 8.426a1.496 1.496 0 01-2.113 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});