define("ember-svg-jar/inlined/ibm-filter-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 5.707L14.293 5 12 7.293 9.707 5 9 5.707 11.293 8 9 10.293l.707.707L12 8.707 14.293 11l.707-.707L12.707 8 15 5.707z\"/><path d=\"M2 2a1 1 0 00-1 1v1.585a1 1 0 00.293.708L5 9v4a1 1 0 001 1h2a1 1 0 001-1v-1H8v1H6V8.585l-4-4V3h10v1h1V3a1 1 0 00-1-1H2z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});