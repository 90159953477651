define("ember-svg-jar/inlined/ibm-finance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M30 28H2v2h28v-2zm-3-17a1 1 0 001-1V7a1 1 0 00-.66-.94l-11-4a1 1 0 00-.68 0l-11 4A1 1 0 004 7v3a1 1 0 001 1h1v13H4v2h24v-2h-2V11h1zM6 7.7l10-3.64L26 7.7V9H6V7.7zM18 24h-4V11h4v13zM8 11h4v13H8V11zm16 13h-4V11h4v13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});