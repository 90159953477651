define("ember-svg-jar/inlined/affinity-grayscale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.752 19.073a3.133 3.133 0 01-1.666-.13l-1.61 1.598a4.943 4.943 0 003.51 8.404 4.95 4.95 0 003.489-1.414l1.523-1.526a3.123 3.123 0 01-.028-1.72l-5.218-5.212zm5.274-11.419a3.1 3.1 0 01-.08-1.713l-1.48-1.48a4.95 4.95 0 00-8.413 3.505 4.943 4.943 0 001.413 3.486l1.61 1.608a3.116 3.116 0 011.667-.135l5.272-5.272.011.001zm3.887 14.444l5.195-5.18a3.142 3.142 0 010-1.827l-5.387-5.38a3.073 3.073 0 01-1.403 0l-5.228 5.217a3.128 3.128 0 010 2.14l5.026 5.02a3.136 3.136 0 011.79.01m2.127 2.22l5.29-5.285a3.097 3.097 0 001.697-.039l1.55 1.547a4.942 4.942 0 01-.07 6.925 4.95 4.95 0 01-6.93.063l-1.563-1.561a3.12 3.12 0 00.126-.877c0-.26-.033-.52-.1-.773zm1.531-19.85l-1.509 1.507a3.09 3.09 0 01-.076 1.645l5.358 5.347a3.1 3.1 0 011.68.037l1.547-1.546a4.943 4.943 0 00-3.512-8.405 4.95 4.95 0 00-3.488 1.414zM28.238 16c0 .259-.034.517-.099.768l1.602 1.609a8.012 8.012 0 01-.141 11.197 8.024 8.024 0 01-11.206.13l-1.586-1.584a3.132 3.132 0 01-1.59 0l-1.577 1.584A8.024 8.024 0 010 24.027a8.012 8.012 0 012.292-5.653l1.773-1.772a3.076 3.076 0 010-1.206l-1.772-1.77a8.012 8.012 0 01.06-11.28 8.024 8.024 0 0111.288-.05l1.389 1.387a3.109 3.109 0 011.962.013l1.403-1.4a8.024 8.024 0 0111.253.086 8.012 8.012 0 01.094 11.244l-1.605 1.602c.066.253.1.514.1.777\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});