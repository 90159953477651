define("ember-svg-jar/inlined/ibm-security-services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M30 24v-2h-2.101a4.966 4.966 0 00-.732-1.753l1.49-1.49-1.414-1.414-1.49 1.49A4.968 4.968 0 0024 18.101V16h-2v2.101a4.968 4.968 0 00-1.753.732l-1.49-1.49-1.414 1.414 1.49 1.49A4.968 4.968 0 0018.101 22H16v2h2.101a4.97 4.97 0 00.732 1.753l-1.49 1.49 1.414 1.414 1.49-1.49a4.968 4.968 0 001.753.732V30h2v-2.101a4.968 4.968 0 001.753-.732l1.49 1.49 1.414-1.414-1.49-1.49A4.968 4.968 0 0027.899 24H30zm-7 2a3 3 0 110-5.999A3 3 0 0123 26z\"/><path d=\"M14 26.667l-3.234-1.725A8.986 8.986 0 016 17V4h20v10h2V4a2.002 2.002 0 00-2-2H6a2.002 2.002 0 00-2 2v13a10.981 10.981 0 005.824 9.707L14 28.934v-2.267z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});