define("ember-svg-jar/inlined/font-awesome-twitter-brand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M28.71 9.482c.02.285.02.569.02.853 0 8.67-6.598 18.66-18.659 18.66A18.533 18.533 0 010 26.05c.528.06 1.036.081 1.584.081 3.066 0 5.888-1.036 8.142-2.802a6.57 6.57 0 01-6.132-4.548c.406.06.812.101 1.238.101.59 0 1.178-.081 1.726-.223A6.56 6.56 0 011.3 12.223v-.08a6.604 6.604 0 002.965.832A6.554 6.554 0 011.34 7.513a6.51 6.51 0 01.893-3.31 18.642 18.642 0 0013.523 6.863 7.404 7.404 0 01-.162-1.503 6.555 6.555 0 016.558-6.558c1.889 0 3.594.792 4.792 2.071a12.912 12.912 0 004.163-1.584 6.54 6.54 0 01-2.884 3.615A13.148 13.148 0 0032 6.09a14.099 14.099 0 01-3.29 3.391z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});