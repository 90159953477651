define("ember-svg-jar/inlined/ibm-partnership", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M8 9a4 4 0 114-4 4 4 0 01-4 4zm0-6a2 2 0 102 2 2 2 0 00-2-2zm16 6a4 4 0 114-4 4 4 0 01-4 4zm0-6a2 2 0 102 2 2 2 0 00-2-2zm2 27h-4a2 2 0 01-2-2v-7h2v7h4v-9h2v-6a1 1 0 00-1-1h-6.42L16 20l-4.58-8H5a1 1 0 00-1 1v6h2v9h4v-7h2v7a2 2 0 01-2 2H6a2 2 0 01-2-2v-7a2 2 0 01-2-2v-6a3 3 0 013-3h7.58L16 16l3.42-6H27a3 3 0 013 3v6a2 2 0 01-2 2v7a2 2 0 01-2 2z\"/><path data-name=\"&lt;Transparent Rectangle&gt;\" fill=\"none\" d=\"M0 0h32v32H0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
});