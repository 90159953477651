define("ember-svg-jar/inlined/ibm-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M29.25 6.76a6 6 0 00-8.5 0l1.42 1.42a4 4 0 115.67 5.67l-8 8a4 4 0 11-5.67-5.66l1.41-1.42-1.41-1.42-1.42 1.42a6 6 0 000 8.5A6 6 0 0017 25a6 6 0 004.27-1.76l8-8a6 6 0 00-.02-8.48z\"/><path d=\"M4.19 24.82a4 4 0 010-5.67l8-8a4 4 0 015.67 0A3.94 3.94 0 0119 14a4 4 0 01-1.17 2.85L15.71 19l1.42 1.42 2.12-2.12a6 6 0 00-8.51-8.51l-8 8a6 6 0 000 8.51A6 6 0 007 28a6.07 6.07 0 004.28-1.76l-1.42-1.42a4 4 0 01-5.67 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32",
      "fill": "currentColor"
    }
  };
});