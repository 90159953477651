define("ember-svg-jar/inlined/metric-alerts-log-percentage-change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 31c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C7.716 1 1 7.716 1 16c0 8.284 6.716 15 15 15zm-2.424-20.076a1.875 1.875 0 11-2.653 2.652 1.875 1.875 0 012.653-2.652zm4.848 7.5a1.875 1.875 0 112.652 2.653 1.875 1.875 0 01-2.652-2.653zm1.932-7.443l.663.663a.937.937 0 010 1.326l-8.05 8.049a.937.937 0 01-1.325 0l-.663-.663a.937.937 0 010-1.326l8.05-8.049a.937.937 0 011.325 0z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});