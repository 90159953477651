define("ember-svg-jar/inlined/font-awesome-ban-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 .5C7.44.5.5 7.44.5 16c0 8.56 6.94 15.5 15.5 15.5 8.56 0 15.5-6.94 15.5-15.5C31.5 7.44 24.56.5 16 .5zm8.132 7.368c4.09 4.09 4.375 10.343 1.292 14.728L9.404 6.576c4.388-3.085 10.64-2.796 14.728 1.292zM7.868 24.132c-4.09-4.09-4.375-10.343-1.292-14.728l16.02 16.02c-4.388 3.085-10.64 2.796-14.728-1.292z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});