define("ember-svg-jar/inlined/font-awesome-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M27 4h-3V.75a.752.752 0 00-.75-.75h-2.5a.752.752 0 00-.75.75V4h-8V.75a.752.752 0 00-.75-.75h-2.5A.752.752 0 008 .75V4H5a3 3 0 00-3 3v22a3 3 0 003 3h22a3 3 0 003-3V7a3 3 0 00-3-3zm-.375 25H5.375A.376.376 0 015 28.625V10h22v18.625a.376.376 0 01-.375.375z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});