define("ember-svg-jar/inlined/font-awesome-heading-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M27 4v20h2a1 1 0 011 1v2a1 1 0 01-1 1H19a1 1 0 01-1-1v-2a1 1 0 011-1h2v-8H9v8h2a1 1 0 011 1v2a1 1 0 01-1 1H1a1 1 0 01-1-1v-2a1 1 0 011-1h2V4H1a1 1 0 01-1-1V1a1 1 0 011-1h10a1 1 0 011 1v2a1 1 0 01-1 1H9v8h12V4h-2a1 1 0 01-1-1V1a1 1 0 011-1h10a1 1 0 011 1v2a1 1 0 01-1 1h-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 30 28"
    }
  };
});