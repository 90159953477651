define("ember-svg-jar/inlined/ibm-string-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M29 22h-5a2.003 2.003 0 01-2-2v-6a2.002 2.002 0 012-2h5v2h-5v6h5zM18 12h-4V8h-2v14h6a2.003 2.003 0 002-2v-6a2.002 2.002 0 00-2-2zm-4 8v-6h4v6zM8 12H3v2h5v2H4a2 2 0 00-2 2v2a2 2 0 002 2h6v-8a2.002 2.002 0 00-2-2zm0 8H4v-2h4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});