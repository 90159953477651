define("ember-svg-jar/inlined/ibm-chart-radial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 30A14.016 14.016 0 012 16h2A12 12 0 1016 4V2a14 14 0 010 28z\"/><path d=\"M16 26A10.011 10.011 0 016 16h2a8 8 0 108-8V6a10 10 0 010 20z\"/><path d=\"M16 22a6.007 6.007 0 01-6-6h2a4 4 0 104-4v-2a6 6 0 110 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});