define("ember-svg-jar/inlined/ibm-expand-categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13 13h-3v1h3v-1zM14 9h-4v1h4V9zM15 5h-5v1h5V5zM8.5 2h-1v12h1V2zM5.293 1.98L3.5 3.623 1.706 1.98 1 2.687 3.5 5 6 2.687l-.707-.707z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});