define("ember-svg-jar/inlined/metric-alerts-log-fixed-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 31c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C7.716 1 1 7.716 1 16c0 8.284 6.716 15 15 15zm5.887-12.188v-.45h-.075v-.525l-.075-.3-.15-.45-.224-.337-.9-.6-.6-.15.15-.075.112-.038.15-.037.113-.038.112-.075.15-.112.225-.15.15-.113.225-.225.225-.337.037-.15.075-.15.038-.113-.038-.112.038-.15v-.9l-.038-.113-.037-.15-.15-.45-.3-.45-.113-.112-.562-.375-.225-.075-.15-.038-.113-.037-.3-.075-.337-.113-.15-.037h-2.1l-.113.037h-.15l-.562.188-.45.112-.675.225-.112.075-.113.038-.113.075.038.15v.15l.037.112.038.15v.263l.075.3v.15l.038.15.075.037v.15l.037.15.45-.3.337-.112.15-.038.113-.037.9-.225h1.2l.15.037.225.075.075.075.225.075.225.225.075.113.037.112.038.15v.3l-.038.113-.037.15-.15.225-.113.112-.112.075-.45.15-.15.038-.113.037-.15.038h-1.724l.037.15v.15l.075.3v.15l.038.15v.075l.037.15v.15l.075.3v.15l.038.15h1.35l.15.037h.15l.3.075.112.038.075.037.225.075.075.038.113.075.112.112.15.225.15.188.038.112v.45l-.038.15-.037.113-.15.225-.113.112-.113.075-.45.15-.3.075H17.05l-.3-.075-.113-.037-.15-.038-.112-.037-.75-.188-.113-.037-.15-.038-.112-.075-.15-.037-.112-.075-.038.15v.112l-.075.3v.15l-.037.113v.15l-.038.15-.038.112v.15l-.075.3v.113l-.037.15.675.225.15.037.113.038.15.037.112.038.15.037.225.075.15.038.113.037.15.038h.15l.112.037h1.95l.15-.037h.15l.113-.075.6-.15.224-.075.15-.038.113-.037.563-.375.112-.113.113-.075.112-.112.375-.563.038-.112.037-.15.075-.113.113-.45zm-8.325-4.425a.286.286 0 00.075-.187c0-.075 0-.15-.075-.15l-2.024-2.025a.286.286 0 00-.188-.075c-.075 0-.112 0-.188.075L9.137 14.05a.286.286 0 00-.075.187c0 .075 0 .113.075.188a.286.286 0 00.188.075h4.05c.075 0 .15-.038.188-.113z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});