define("ember-svg-jar/inlined/ibm-security", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14 16.59L11.41 14 10 15.41l4 4 8-8L20.59 10 14 16.59z\"/><path d=\"M16 30l-6.176-3.293A10.982 10.982 0 014 17V4a2.002 2.002 0 012-2h20a2.002 2.002 0 012 2v13a10.983 10.983 0 01-5.824 9.707L16 30zM6 4v13a8.985 8.985 0 004.766 7.942L16 27.733l5.234-2.79A8.985 8.985 0 0026 17V4H6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});