define("ember-svg-jar/inlined/ibm-list-numbered", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M30 22H16v2h14v-2zm0-14H16v2h14V8zM8 12V4H6v1H4v2h2v5H4v2h6v-2H8zm2 16H4v-4a2 2 0 012-2h2v-2H4v-2h4a2 2 0 012 2v2a2 2 0 01-2 2H6v2h4v2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});