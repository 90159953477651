define("ember-svg-jar/inlined/ibm-text-strikethrough", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M30 14.817H18.282a45.295 45.295 0 00-1.56-.402c-3.276-.796-5.13-1.379-5.13-4.102a3.54 3.54 0 01.2-1.384c.157-.442.402-.846.718-1.187a5.497 5.497 0 013.516-1.306c3.3-.084 4.824 1.066 6.068 2.816l1.885-1.413a8.78 8.78 0 00-3.485-3.006 8.515 8.515 0 00-4.484-.794 7.781 7.781 0 00-5.133 1.99A5.805 5.805 0 009.637 8a5.927 5.927 0 00-.377 2.313 5.364 5.364 0 00.46 2.514 5.21 5.21 0 001.563 1.99H2v2.397h15.927c2.295.684 3.667 1.573 3.702 4.025a3.83 3.83 0 01-.205 1.544 3.755 3.755 0 01-.8 1.324 6.679 6.679 0 01-4.34 1.496 7.578 7.578 0 01-3.363-.902 7.802 7.802 0 01-2.64-2.323l-1.789 1.54a10.02 10.02 0 003.42 2.96A9.738 9.738 0 0016.247 28h.117a8.806 8.806 0 005.89-2.18 6.107 6.107 0 001.33-2.122 6.238 6.238 0 00.378-2.495 6.037 6.037 0 00-1.34-3.989H30v-2.396z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});