define("ember-svg-jar/inlined/ibm-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M24.207 21.997h.222A6 6 0 0030.509 16a6 6 0 00-1.781-4.242A6.07 6.07 0 0024.4 9.976l-5.225.02v.001l-11.604.007A6 6 0 001.491 16a6 6 0 001.781 4.243A6.069 6.069 0 007.6 22.025l13.225-.021V22l2.984-.004c.133.005.266.004.398 0zm-4.683-2a6.001 6.001 0 01.006-7.999H7.571a4.002 4.002 0 000 8.004l11.953-.006zm6.698-7.322a4 4 0 10-4.444 6.65 4 4 0 004.445-6.65z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});