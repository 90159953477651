define("ember-svg-jar/inlined/font-awesome-copy-regular", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M29.121 4.121L25.88.88A3 3 0 0023.757 0H13a3 3 0 00-3 3v3H5a3 3 0 00-3 3v20a3 3 0 003 3h14a3 3 0 003-3v-3h5a3 3 0 003-3V6.243a3 3 0 00-.879-2.122zM18.625 29H5.375A.375.375 0 015 28.625V9.375A.375.375 0 015.375 9H10v14a3 3 0 003 3h6v2.625a.375.375 0 01-.375.375zm8-6h-13.25a.375.375 0 01-.375-.375V3.375A.375.375 0 0113.375 3H20v5.5a1.5 1.5 0 001.5 1.5H27v12.625a.375.375 0 01-.375.375zM27 7h-4V3h.602c.1 0 .195.04.265.11l3.023 3.023a.376.376 0 01.11.265V7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});