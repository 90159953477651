define("ember-svg-jar/inlined/ibm-scales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20 16a5 5 0 0010 0 1 1 0 00-.105-.447l-3.999-7.997a.891.891 0 00-.045-.081A1 1 0 0025 7h-6.178A3.015 3.015 0 0017 5.184V2h-2v3.184A3.015 3.015 0 0013.178 7H7a1 1 0 00-.894.553l-4 8A1 1 0 002 16a5 5 0 0010 0 1 1 0 00-.105-.447L8.617 9h4.56A3.015 3.015 0 0015 10.815V28H6v2h20v-2h-9V10.816A3.015 3.015 0 0018.822 9h4.56l-3.277 6.553A1 1 0 0020 16zM7 19a2.996 2.996 0 01-2.815-2h5.63A2.996 2.996 0 017 19zm2.382-4H4.618L7 10.236zM16 9a1 1 0 111-1 1 1 0 01-1 1zm9 10a2.996 2.996 0 01-2.815-2h5.63A2.996 2.996 0 0125 19zm0-8.764L27.382 15h-4.764z\"/><path d=\"M0 0h32v32H0z\" fill=\"none\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});