define("ember-light-table/components/light-table", ["exports", "@ember/component", "@ember/array", "@ember/object", "@ember/object/computed", "@ember/object/internals", "@ember/utils", "@ember/debug", "@ember/service", "ember-light-table/classes/Table", "ember-light-table/utils/css-styleify", "@ember/template-factory"], function (_exports, _component, _array, _object, _computed, _internals, _utils, _debug, _service, _Table, _cssStyleify, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ember-light-table {{if this.occlusion "occlusion"}}"
    id={{or @id this.tableId}}
    style={{this.style}}
    ...attributes
  >
    {{yield
      (hash
        head=(component
          "lt-head"
          tableId=(or @id this.tableId)
          table=this.table
          tableActions=this.tableActions
          extra=this.extra
          tableClassNames=this.tableClassNames
          sharedOptions=this.sharedOptions
        )
        body=(component
          "lt-body"
          tableId=(or @id this.tableId)
          table=this.table
          tableActions=this.tableActions
          extra=this.extra
          tableClassNames=this.tableClassNames
          sharedOptions=this.sharedOptions
        )
        foot=(component
          "lt-foot"
          tableId=(or @id this.tableId)
          table=this.table
          tableActions=this.tableActions
          extra=this.extra
          tableClassNames=this.tableClassNames
          sharedOptions=this.sharedOptions
        )
      )
    }}
  </div>
  */
  {
    "id": "FKe5l1K1",
    "block": "[[[11,0],[16,0,[29,[\"ember-light-table \",[52,[30,0,[\"occlusion\"]],\"occlusion\"]]]],[16,1,[28,[37,1],[[30,1],[30,0,[\"tableId\"]]],null]],[16,5,[30,0,[\"style\"]]],[17,2],[12],[1,\"\\n  \"],[18,3,[[28,[37,3],null,[[\"head\",\"body\",\"foot\"],[[50,\"lt-head\",0,null,[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[28,[37,1],[[30,1],[30,0,[\"tableId\"]]],null],[30,0,[\"table\"]],[30,0,[\"tableActions\"]],[30,0,[\"extra\"]],[30,0,[\"tableClassNames\"]],[30,0,[\"sharedOptions\"]]]]],[50,\"lt-body\",0,null,[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[28,[37,1],[[30,1],[30,0,[\"tableId\"]]],null],[30,0,[\"table\"]],[30,0,[\"tableActions\"]],[30,0,[\"extra\"]],[30,0,[\"tableClassNames\"]],[30,0,[\"sharedOptions\"]]]]],[50,\"lt-foot\",0,null,[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[28,[37,1],[[30,1],[30,0,[\"tableId\"]]],null],[30,0,[\"table\"]],[30,0,[\"tableActions\"]],[30,0,[\"extra\"]],[30,0,[\"tableClassNames\"]],[30,0,[\"sharedOptions\"]]]]]]]]]],[1,\"\\n\"],[13]],[\"@id\",\"&attrs\",\"&default\"],false,[\"if\",\"or\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-light-table/components/light-table.hbs",
    "isStrictMode": false
  });
  function intersections(array1, array2) {
    return array1.filter(n => {
      return array2.indexOf(n) > -1;
    });
  }

  /**
   * @module Light Table
   * @main light-table
   */

  /**
   * ```hbs
   * <LightTable @table={{this.table}} as |t| >
   *   <t.head />
   *   <t.nody />
   *   <t.foot />
   * </LightTable>
   * ```
   *
   * Please see the documentation for the [Head](../classes/t.head.html), [Body](../classes/t.body.html), and [Foot](../classes/t.foot.html) components
   * for more details on all possible options and actions.
   *
   * @class light-table
   * @main Components
   */
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    tagName: '',
    media: (0, _service.inject)(),
    scrollbarThickness: (0, _service.inject)(),
    /**
     * @property table
     * @type {Table}
     */
    table: null,
    /**
     * This is used to propagate custom user defined actions to custom cell and header components.
     * As an example, lets say I have a table with a column defined with `cellComponent: 'delete-user'`
     *
     * ```hbs
     *  <LightTable
     *    @table={{this.table}}
     *    @tableActions={{hash deleteUser=this.deleteUser}}
     *    as |t|
     *  >
     *   <t.head />
     *   <t.body />
     *   <t.foot />
     * </LightTable>
     * ```
     *
     * Now in the `delete-user` component, we can access that `deleteUser` action and pass it the
     * row object which will bubble all the way to where you defined that action.
     *
     * ```hbs
     * * <button {{on 'click' (fn @tableActions.deleteUser @row)}}>Delete Me</button>
     * ```
     *
     *
     * @property tableActions
     * @type {Object}
     */
    tableActions: null,
    /**
     * Object to store any arbitrary configuration meant to be used by custom
     * components.
     *
     * ```hbs
     * <LightTable @table={{this.table}}
     *   @extra={{hash
     *     highlightColor="yellow"
     *    }}
     *    as |t|
     *  }}
     *   <t.head />
     *   <t.body />
     *   <t.foot />
     * </LightTable>
     * ```
     *
     * Now in all custom components, you can access this value like so:
     *
     * ```hbs
     * <span style="background: {{extra.highlightColor}}">{{value}}<span>
     * ```
     *
     * @property extra
     * @type {Object}
     */
    extra: null,
    /**
     * Table height.
     *
     * @property height
     * @type {String}
     * @default null
     */
    height: null,
    /**
     * Class names that will be added to all <table> tags
     *
     * @property tableClassNames
     * @type {String}
     * @default ''
     */
    tableClassNames: '',
    /**
     * Enable responsive behavior
     *
     * @property responsive
     * @type {Boolean}
     * @default false
     */
    responsive: false,
    /**
     * A hash to determine the number of columns to show per given breakpoint.
     * If this is specified, it will override any column specific breakpoints.
     *
     * If we have the following breakpoints defined in `app/breakpoints.js`:
     *
     * - mobile
     * - tablet
     * - desktop
     *
     * The following hash can be passed in:
     *
     * ```js
     * {
     *  mobile: 2,
     *  tablet: 4
     * }
     * ```
     *
     * If there is no rule specified for a given breakpoint (i.e. `desktop`),
     * all columns will be shown.
     *
     * @property breakpoints
     * @type {Object}
     * @default null
     */
    breakpoints: null,
    /**
     * Toggles occlusion rendering functionality. Currently experimental.
     * If set to true, you must set {{#crossLink 't.body/estimatedRowHeight:property'}}{{/crossLink}} to
     * something other than the default value.
     *
     * @property occlusion
     * @type Boolean
     * @default False
     */
    occlusion: false,
    /**
     * Estimated size of a row. Used in `vertical-collection` to determine roughly the number
     * of rows exist out of the viewport.
     *
     * @property estimatedRowHeight
     * @type Number
     * @default false
     */
    estimatedRowHeight: 0,
    /**
     * Whether `vertical-collection` should recycle table rows. This speeds up performance with occlusion
     * rendering but may cause problems if any components expect to reset their state to the initial state
     * with every rerender of the list.
     *
     * @property shouldRecycle
     * @type Boolean
     * @default true
     */
    shouldRecycle: true,
    /**
     * Table component shared options
     *
     * @property sharedOptions
     * @type {Object}
     * @private
     */
    sharedOptions: (0, _object.computed)('estimatedRowHeight', 'height', 'occlusion', 'shouldRecycle', function () {
      return {
        height: this.height,
        fixedHeader: false,
        fixedFooter: false,
        occlusion: this.occlusion,
        estimatedRowHeight: this.estimatedRowHeight,
        shouldRecycle: this.shouldRecycle
      };
    }).readOnly(),
    visibleColumns: (0, _computed.readOnly)('table.visibleColumns'),
    /**
     * Calculates the total width of the visible columns via their `width`
     * propert.
     *
     * Returns 0 for the following conditions
     *  - All widths are not set
     *  - Widths are not the same unit
     *  - Unit cannot be determined
     *
     * @property totalWidth
     * @type {Number}
     * @private
     */
    totalWidth: (0, _object.computed)('visibleColumns.@each.width', function () {
      let visibleColumns = this.visibleColumns;
      let widths = visibleColumns.getEach('width');
      let unit = (widths[0] || '').match(/\D+$/);
      let totalWidth = 0;
      if ((0, _utils.isEmpty)(unit)) {
        return 0;
      }
      unit = unit[0];

      /*
       1. Check if all widths are present
       2. Check if all widths are the same unit
       */
      for (let i = 0; i < widths.length; i++) {
        let width = widths[i];
        if ((0, _utils.isNone)(width) || width.indexOf(unit) === -1) {
          return 0;
        }
        totalWidth += parseInt(width, 10);
      }
      return `${totalWidth}${unit}`;
    }),
    style: (0, _object.computed)('height', 'occlusion', 'scrollbarThickness.thickness', 'totalWidth', function () {
      let totalWidth = this.totalWidth;
      let style = {
        height: this.height
      };
      if (totalWidth) {
        if (this.occlusion) {
          const scrollbarThickness = this.scrollbarThickness.thickness;
          style.width = `calc(${totalWidth} + ${scrollbarThickness}px)`;
        } else {
          style.width = totalWidth;
        }
        style.overflowX = 'auto';
      }
      return (0, _cssStyleify.default)(style);
    }),
    init() {
      this._super(...arguments);
      let table = this.table;
      (false && !(table instanceof _Table.default) && (0, _debug.assert)('[ember-light-table] table must be an instance of Table', table instanceof _Table.default));
      this.set('tableId', (0, _internals.guidFor)(this));
      if ((0, _utils.isNone)(this.media)) {
        this.set('responsive', false);
      } else {
        this.media.on('mediaChanged', () => this.onMediaChange());
      }
      this.onMediaChange();
    },
    onMediaChange: (0, _object.observer)('table.allColumns.[]', function () {
      let responsive = this.responsive;
      let matches = this.media.matches;
      let breakpoints = this.breakpoints;
      let table = this.table;
      let numColumns = 0;
      if (!responsive) {
        return;
      }
      this.send('onBeforeResponsiveChange', matches);
      if (!(0, _utils.isNone)(breakpoints)) {
        Object.keys(breakpoints).forEach(b => {
          if (matches.indexOf(b) > -1) {
            numColumns = Math.max(numColumns, breakpoints[b]);
          }
        });
        this._displayColumns(numColumns);
      } else {
        table.get('allColumns').forEach(c => {
          let breakpoints = c.get('breakpoints');
          let isMatch = (0, _utils.isEmpty)(breakpoints) || intersections(matches, breakpoints).length > 0;
          c.set('responsiveHidden', !isMatch);
        });
      }
      this.send('onAfterResponsiveChange', matches);
    }),
    _displayColumns(numColumns) {
      let table = this.table;
      let hiddenColumns = table.get('responsiveHiddenColumns');
      let visibleColumns = table.get('visibleColumns');
      if (!numColumns) {
        hiddenColumns.setEach('responsiveHidden', false);
      } else if (visibleColumns.length > numColumns) {
        (0, _array.A)(visibleColumns.slice(numColumns, visibleColumns.length)).setEach('responsiveHidden', true);
      } else if (visibleColumns.length < numColumns) {
        (0, _array.A)(hiddenColumns.slice(0, numColumns - visibleColumns.length)).setEach('responsiveHidden', false);
      }
    },
    // No-ops for closure actions
    onBeforeResponsiveChange() {},
    onAfterResponsiveChange() {},
    actions: {
      /**
       * onBeforeResponsiveChange action.
       * Called before any column visibility is altered.
       *
       * @event onBeforeResponsiveChange
       * @param  {Array} matches list of matching breakpoints
       */
      onBeforeResponsiveChange(/* matches */
      ) {
        this.onBeforeResponsiveChange(...arguments);
      },
      /**
       * onAfterResponsiveChange action.
       * Called after all column visibility has been altered.
       *
       * @event onAfterResponsiveChange
       * @param  {Array} matches list of matching breakpoints
       */
      onAfterResponsiveChange(/* matches */
      ) {
        this.onAfterResponsiveChange(...arguments);
      }
    }
  }));
});