define("ember-svg-jar/inlined/font-awesome-tag-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 15.757V3a3 3 0 013-3h12.757a3 3 0 012.122.879L31.12 14.12a3 3 0 010 4.243L18.364 31.12a3 3 0 01-4.243 0L.88 17.88A3 3 0 010 15.757zM7 4a3 3 0 100 6 3 3 0 000-6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});