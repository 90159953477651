define("ember-svg-jar/inlined/font-awesome-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.695 2.022L10.95 9.614l-8.38 1.222c-1.502.218-2.104 2.07-1.015 3.131l6.062 5.907-1.434 8.344c-.258 1.508 1.33 2.638 2.661 1.933l7.495-3.94 7.496 3.94c1.33.7 2.919-.425 2.66-1.933l-1.433-8.344 6.062-5.907c1.09-1.06.487-2.913-1.015-3.131L21.73 9.614l-3.745-7.592c-.67-1.354-2.615-1.371-3.291 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});