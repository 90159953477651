define("ember-svg-jar/inlined/ibm-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M25.125 28H28L17.937 4h-2.875L5 28h2.875l2.314-5.647h12.564L25.125 28zm-13.757-8.47l4.945-11.817h.374l4.902 11.816h-10.22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});