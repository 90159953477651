define("ember-svg-jar/inlined/ibm-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M26 6v4H6V6h20m0-2H6a2 2 0 00-2 2v4a2 2 0 002 2h20a2 2 0 002-2V6a2 2 0 00-2-2zM10 16v10H6V16h4m0-2H6a2 2 0 00-2 2v10a2 2 0 002 2h4a2 2 0 002-2V16a2 2 0 00-2-2zm16 2v10H16V16h10m0-2H16a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V16a2 2 0 00-2-2z\"/><path d=\"M0 0h32v32H0z\" fill=\"none\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});