define("ember-svg-jar/inlined/font-awesome-unlink-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19.004 25.369a.75.75 0 010 1.06l-2.792 2.792c-3.704 3.704-9.73 3.704-13.435 0-3.704-3.704-3.704-9.73 0-13.435l2.792-2.792a.75.75 0 011.061 0l2.475 2.475a.75.75 0 010 1.06l-2.792 2.793a4.505 4.505 0 000 6.364 4.505 4.505 0 006.364 0l2.792-2.792a.75.75 0 011.06 0l2.475 2.475zM15.47 9.105a.75.75 0 001.06 0l2.793-2.792a4.505 4.505 0 016.364 0 4.505 4.505 0 010 6.364l-2.793 2.792a.75.75 0 000 1.06l2.475 2.476a.75.75 0 001.06 0l2.793-2.793c3.704-3.704 3.704-9.73 0-13.435-3.704-3.704-9.73-3.704-13.435 0L12.994 5.57a.75.75 0 000 1.061l2.475 2.475zM30.145 31.56l1.415-1.414a1.5 1.5 0 000-2.122L3.974.44a1.5 1.5 0 00-2.121 0L.438 1.853a1.5 1.5 0 000 2.121L28.024 31.56a1.5 1.5 0 002.121 0z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});