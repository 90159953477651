define("ember-svg-jar/inlined/ibm-user-multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M30 30h-2v-5a5.006 5.006 0 00-5-5v-2a7.008 7.008 0 017 7v5zm-8 0h-2v-5a5.006 5.006 0 00-5-5H9a5.006 5.006 0 00-5 5v5H2v-5a7.008 7.008 0 017-7h6a7.009 7.009 0 017 7v5zM20 2v2a5 5 0 110 10v2a7 7 0 100-14zm-8 2a5 5 0 110 10 5 5 0 010-10zm0-2a7 7 0 100 14 7 7 0 000-14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 32 32"
    }
  };
});