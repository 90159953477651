define("ember-svg-jar/inlined/font-awesome-download-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.5 0h5c.831 0 1.5.669 1.5 1.5V12h5.481c1.113 0 1.669 1.344.881 2.131l-9.506 9.513a1.21 1.21 0 01-1.706 0L5.631 14.13C4.844 13.344 5.4 12 6.512 12H12V1.5c0-.831.669-1.5 1.5-1.5zM32 23.5v7c0 .831-.669 1.5-1.5 1.5h-29C.669 32 0 31.331 0 30.5v-7c0-.831.669-1.5 1.5-1.5h9.169l3.062 3.063a3.203 3.203 0 004.538 0L21.33 22h9.17c.831 0 1.5.669 1.5 1.5zM24.25 29c0-.688-.563-1.25-1.25-1.25-.688 0-1.25.563-1.25 1.25 0 .688.563 1.25 1.25 1.25.688 0 1.25-.563 1.25-1.25zm4 0c0-.688-.563-1.25-1.25-1.25-.688 0-1.25.563-1.25 1.25 0 .688.563 1.25 1.25 1.25.688 0 1.25-.563 1.25-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});