define("ember-svg-jar/inlined/ibm-caret-sorted-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#D4D4D8\" d=\"M24 22l-8 8-8-8h16zM8 10l8-8 8 8H8z\"/><path fill=\"currentColor\" d=\"M24 22l-8 8-8-8h16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 32 32"
    }
  };
});