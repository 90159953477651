define("ember-svg-jar/inlined/ibm-network", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 20a4 4 0 114-4 4.005 4.005 0 01-4 4zm0-6a2 2 0 102 2 2.002 2.002 0 00-2-2zM5 20a4 4 0 114-4 4.005 4.005 0 01-4 4zm0-6a2 2 0 102 2 2.002 2.002 0 00-2-2zM10 31a4 4 0 114-4 4.005 4.005 0 01-4 4zm0-6a2 2 0 102 2 2.002 2.002 0 00-2-2zM22 31a4 4 0 114-4 4.005 4.005 0 01-4 4zm0-6a2 2 0 102 2 2.002 2.002 0 00-2-2zM27 20a4 4 0 114-4 4.005 4.005 0 01-4 4zm0-6a2 2 0 102 2 2.002 2.002 0 00-2-2zM22 9a4 4 0 114-4 4.005 4.005 0 01-4 4zm0-6a2 2 0 102 2 2.002 2.002 0 00-2-2zM10 9a4 4 0 114-4 4.005 4.005 0 01-4 4zm0-6a2 2 0 102 2 2.002 2.002 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});