define("ember-svg-jar/inlined/ibm-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.47 7.83A8.344 8.344 0 008 2.5 8.345 8.345 0 00.53 7.83a.5.5 0 000 .34A8.345 8.345 0 008 13.5a8.346 8.346 0 007.47-5.33.5.5 0 000-.34zM8 12.5c-2.65 0-5.45-1.965-6.465-4.5C2.55 5.465 5.35 3.5 8 3.5s5.45 1.965 6.465 4.5C13.45 10.535 10.65 12.5 8 12.5z\"/><path d=\"M8 5a3 3 0 100 6 3 3 0 000-6zm0 5a2 2 0 110-4 2 2 0 010 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16",
      "fill": "currentColor"
    }
  };
});