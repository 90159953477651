define("ember-svg-jar/inlined/font-awesome-pause", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11 29.938H5a3 3 0 01-3-3v-22a3 3 0 013-3h6a3 3 0 013 3v22a3 3 0 01-3 3zm19-3v-22a3 3 0 00-3-3h-6a3 3 0 00-3 3v22a3 3 0 003 3h6a3 3 0 003-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});