define("ember-svg-jar/inlined/ibm-data-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"22\" cy=\"24\" r=\"2\"/><path d=\"M29.777 23.479A8.64 8.64 0 0022 18a8.64 8.64 0 00-7.777 5.479L14 24l.223.521A8.64 8.64 0 0022 30a8.64 8.64 0 007.777-5.479L30 24zM22 28a4 4 0 114-4 4.005 4.005 0 01-4 4z\"/><circle cx=\"8\" cy=\"8\" r=\"1\"/><circle cx=\"8\" cy=\"16\" r=\"1\"/><circle cx=\"8\" cy=\"24\" r=\"1\"/><path d=\"M5 21h7v-2H5v-6h16v3h2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v22a2 2 0 002 2h7v-2H5zM5 5h16v6H5z\"/><path d=\"M0 0h32v32H0z\" fill=\"none\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});