define("ember-svg-jar/inlined/ibm-expand-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 10h14a2.002 2.002 0 002-2V4a2.002 2.002 0 00-2-2H12a2.002 2.002 0 00-2 2v1H6V2H4v23a2.002 2.002 0 002 2h4v1a2.002 2.002 0 002 2h14a2.002 2.002 0 002-2v-4a2.002 2.002 0 00-2-2H12a2.002 2.002 0 00-2 2v1H6v-8h4v1a2.002 2.002 0 002 2h14a2.002 2.002 0 002-2v-4a2.002 2.002 0 00-2-2H12a2.002 2.002 0 00-2 2v1H6V7h4v1a2.002 2.002 0 002 2zm0-6h14l.001 4H12zm0 20h14l.001 4H12zm0-10h14l.001 4H12z\"/><path d=\"M0 0h32v32H0z\" fill=\"none\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});