define("ember-svg-jar/inlined/ibm-condition-wait-point", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 8l-8 8 8 8 8-8-8-8z\" fill=\"currentColor\"/><path d=\"M16 4A12 12 0 114 16 12.014 12.014 0 0116 4zm0-2a14 14 0 100 28 14 14 0 000-28z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});