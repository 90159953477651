define("ember-svg-jar/inlined/ibm-launch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M26 28H6a2.003 2.003 0 01-2-2V6a2.003 2.003 0 012-2h10v2H6v20h20V16h2v10a2.003 2.003 0 01-2 2z\"/><path d=\"M20 2v2h6.586L18 12.586 19.414 14 28 5.414V12h2V2H20z\"/><path d=\"M0 0h32v32H0z\" fill=\"none\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});