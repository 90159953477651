define("ember-svg-jar/inlined/ibm-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6 18l1.41 1.41L15 11.83V30h2V11.83l7.59 7.58L26 18 16 8 6 18zM6 8V4h20v4h2V4a2 2 0 00-2-2H6a2 2 0 00-2 2v4h2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});