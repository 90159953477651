define("ember-svg-jar/inlined/ibm-fit-to-screen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11 8h1V4H8v1h3v3zM4 12h4v-1H5V8H4v4z\"/><path d=\"M13 14H3a1.001 1.001 0 01-1-1V3a1.001 1.001 0 011-1h10a1.001 1.001 0 011 1v10a1.002 1.002 0 01-1 1zM3 3v10h10V3H3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16",
      "fill": "currentColor"
    }
  };
});