define("ember-svg-jar/inlined/font-awesome-caret-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21.54 8.25v16.082c0 1.112-1.343 1.668-2.13.88l-8.044-8.037a1.252 1.252 0 010-1.769l8.043-8.037c.788-.787 2.132-.231 2.132.881z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});