define("ember-svg-jar/inlined/font-awesome-angle-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.062 9.444l8.497 8.583a1.519 1.519 0 010 2.139l-1.412 1.426a1.483 1.483 0 01-2.118 0L17 15.515l-6.023 6.084a1.483 1.483 0 01-2.118 0L7.44 20.172a1.52 1.52 0 010-2.139l8.498-8.583c.587-.6 1.537-.6 2.124-.006z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 33 32"
    }
  };
});