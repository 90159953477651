define("ember-svg-jar/inlined/ibm-catalog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M26 2H8a2 2 0 00-2 2v4H4v2h2v5H4v2h2v5H4v2h2v4a2 2 0 002 2h18a2 2 0 002-2V4a2 2 0 00-2-2zm0 26H8v-4h2v-2H8v-5h2v-2H8v-5h2V8H8V4h18v24z\"/><path d=\"M22 8h-8v2h8V8zm0 7h-8v2h8v-2zm0 7h-8v2h8v-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});