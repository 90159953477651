define("ember-svg-jar/inlined/ibm-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24 4h2v24h-2zm-6 2v20h-4V6h4m0-2h-4a2 2 0 00-2 2v20a2 2 0 002 2h4a2 2 0 002-2V6a2 2 0 00-2-2zM6 4h2v24H6z\"/><path d=\"M0 0h32v32H0z\" fill=\"none\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});