define("ember-svg-jar/inlined/font-awesome-plus-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M28 13h-9V4a2 2 0 00-2-2h-2a2 2 0 00-2 2v9H4a2 2 0 00-2 2v2a2 2 0 002 2h9v9a2 2 0 002 2h2a2 2 0 002-2v-9h9a2 2 0 002-2v-2a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});