define("ember-svg-jar/inlined/ibm-table-of-contents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M22 6H4v2h18V6zm0 6H4v2h18v-2zm0 6H4v2h18v-2zm0 6H4v2h18v-2zm6-18h-2v2h2V6zm0 6h-2v2h2v-2zm0 6h-2v2h2v-2zm0 6h-2v2h2v-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});