define("ember-svg-jar/inlined/font-awesome-quote-right-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M29 2h-8a3 3 0 00-3 3v8a3 3 0 003 3h5v4c0 2.206-1.794 4-4 4h-.5c-.831 0-1.5.669-1.5 1.5v3c0 .831.669 1.5 1.5 1.5h.5c5.525 0 10-4.475 10-10V5a3 3 0 00-3-3zM11 2H3a3 3 0 00-3 3v8a3 3 0 003 3h5v4c0 2.206-1.794 4-4 4h-.5c-.831 0-1.5.669-1.5 1.5v3c0 .831.669 1.5 1.5 1.5H4c5.525 0 10-4.475 10-10V5a3 3 0 00-3-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});