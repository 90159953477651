define("ember-svg-jar/inlined/ibm-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.159 28a2.571 2.571 0 01-1.817-.754L4.754 15.666A2.572 2.572 0 014 13.849V6.57A2.572 2.572 0 016.571 4h7.277c.681 0 1.335.272 1.817.754l11.579 11.58a2.571 2.571 0 010 3.643l-7.268 7.269a2.571 2.571 0 01-1.817.754zM6.57 5.714a.857.857 0 00-.857.857v7.278a.857.857 0 00.257.608l11.58 11.58a.857.857 0 001.208 0l7.276-7.277a.857.857 0 000-1.209L14.456 5.971a.858.858 0 00-.608-.257H6.57z\"/><path d=\"M10.857 14.286a3.428 3.428 0 110-6.857 3.428 3.428 0 010 6.857zm0-5.143a1.714 1.714 0 100 3.428 1.714 1.714 0 000-3.428z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});