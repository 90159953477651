define("ember-svg-jar/inlined/chart-types-column-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"chart-type-color-fill\" d=\"M9.3 40.1h6.9V23.2H9.3v16.9zm11.4 0h6.9V28h-6.9v12.1zM32.2 16v24.1h6.9V16h-6.9zm11.5 9.8v14.3h6.9V25.8h-6.9z\"/><path fill=\"none\" stroke=\"#000\" stroke-width=\"3\" stroke-linecap=\"square\" stroke-miterlimit=\"10\" d=\"M10.5 33.2l9.4-11.7 10 14.7L40.7 24l8.9-4.3\"/><path stroke=\"#aaa\" fill=\"none\" stroke-width=\"2\" stroke-miterlimit=\"10\" d=\"M9.3 43h41.4\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 60 60"
    }
  };
});