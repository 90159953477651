define("ember-svg-jar/inlined/ibm-preview-not-available", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M30 3.414L28.586 2 2 28.586 3.414 30l2-2H26a2.003 2.003 0 002-2V5.414l2-2zM26 26H7.414l7.793-7.793 2.379 2.379a2 2 0 002.828 0L22 19l4 3.997V26zm0-5.832l-2.586-2.586a2 2 0 00-2.828 0L19 19.168l-2.377-2.377L26 7.414v12.754zM6 22v-3l5-4.997 1.373 1.374 1.416-1.416-1.375-1.375a2 2 0 00-2.828 0L6 16.172V6h16V4H6a2.002 2.002 0 00-2 2v16h2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});