define("ember-svg-jar/inlined/ibm-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.5 10.5a2.5 2.5 0 00-1.944.945L5.9 9.16a2.23 2.23 0 000-1.32l3.655-2.285A2.5 2.5 0 109 4c.002.224.036.446.1.66L5.446 6.945a2.5 2.5 0 100 3.11L9.1 12.34A2.396 2.396 0 009 13a2.5 2.5 0 102.5-2.5zm0-8a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm-8 7.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm8 4.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 16 16"
    }
  };
});