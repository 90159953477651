define("ember-svg-jar/inlined/ibm-percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9 14a5 5 0 110-9.999A5 5 0 019 14zm0-8a3 3 0 100 6 3 3 0 000-6zm17.586-2L4 26.586 5.414 28 28 5.414 26.586 4zM23 28a5 5 0 110-10 5 5 0 010 10zm0-8a3 3 0 100 5.999A3 3 0 0023 20z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});