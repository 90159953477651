define("ember-svg-jar/inlined/chart-types-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"chart-type-color-fill\" d=\"M5.5 9h-2v1h2V9zm4 0h-3v1h3V9zm-4-2h-2v1h2V7zm4 4h-3v1h3v-1z\"/><path d=\"M13.5 1.5h-11a1.001 1.001 0 00-1 1v11a1.001 1.001 0 001 1h11a1 1 0 001-1v-11a1.001 1.001 0 00-1-1zm0 1v2h-11v-2h11zm-11 11v-8h11v8h-11z\"/><path class=\"chart-type-color-fill\" d=\"M5.5 11h-2v1h2v-1zm4-4h-3v1h3V7zm3 2h-2v1h2V9zm0-2h-2v1h2V7zm0 4h-2v1h2v-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 16 16"
    }
  };
});