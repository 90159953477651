define("ember-svg-jar/inlined/font-awesome-trash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 5.25V3.5C2 2.669 2.669 2 3.5 2h7l.588-1.169C11.338.32 11.856 0 12.425 0h7.144a1.5 1.5 0 011.344.831L21.5 2h7c.831 0 1.5.669 1.5 1.5v1.75c0 .412-.337.75-.75.75H2.75A.752.752 0 012 5.25zm25.95 3.544l-1.275 20.393A3.002 3.002 0 0123.681 32H8.32a3.002 3.002 0 01-2.994-2.813L4.05 8.794A.75.75 0 014.8 8h22.406a.75.75 0 01.744.794z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});