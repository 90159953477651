define("ember-svg-jar/inlined/chart-types-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"chart-type-color-fill\" d=\"M9.2 39.9h6.9V29.6H9.2v10.3zm11.6 0h6.9v-8.1h-6.9v8.1zm11.5-13.8v13.8h6.9V26.1h-6.9zm11.5 3.5V40h6.9V29.6h-6.9zm-34.6 0h6.9v-9.4H9.2v9.4z\"/><path class=\"chart-type-color-fill\" d=\"M20.8 31.9h6.9v-8.8h-6.9v8.8zm11.5-17v11.5h6.9V14.9h-6.9zm11.5 6.6v8.4h6.9v-8.4h-6.9z\"/><path fill=\"none\" stroke=\"#aaa\" stroke-width=\"2\" d=\"M9.3 44.1h41.4\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 60 60",
      "class": "icon-column-chart"
    }
  };
});