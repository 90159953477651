define("ember-svg-jar/inlined/navigation-sent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M27.71 4.29a1 1 0 00-1.05-.23l-22 8a1 1 0 000 1.87l9.6 3.84 3.84 9.6a1 1 0 00.9.63 1 1 0 00.92-.66l8-22a1 1 0 00-.21-1.05zM19 24.2l-2.79-7L21 12.41 19.59 11l-4.83 4.83L7.8 13l17.53-6.33z\"/><title>Send alt</title>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32",
      "aria-hidden": "true",
      "style": "will-change:transform",
      "fill": "currentColor"
    }
  };
});