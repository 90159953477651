define("ember-svg-jar/inlined/font-awesome-arrow-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.534 18.097l-1.387-1.388a1.494 1.494 0 010-2.118L15.29 2.44a1.494 1.494 0 012.118 0l12.15 12.15a1.494 1.494 0 010 2.118l-1.387 1.388a1.502 1.502 0 01-2.144-.025l-7.175-7.531v17.962c0 .831-.669 1.5-1.5 1.5h-2c-.831 0-1.5-.669-1.5-1.5V10.541l-7.175 7.53a1.49 1.49 0 01-2.144.026z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});