define("ember-svg-jar/inlined/font-awesome-thumbs-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.5 14h-5A1.5 1.5 0 000 15.5v15A1.5 1.5 0 001.5 32h5A1.5 1.5 0 008 30.5v-15A1.5 1.5 0 006.5 14zM4 29.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM24 5.09c0 2.652-1.623 4.139-2.08 5.91h6.358c2.087 0 3.712 1.734 3.722 3.631.005 1.121-.472 2.328-1.215 3.075l-.007.007c.615 1.458.515 3.502-.582 4.967.543 1.618-.004 3.606-1.024 4.672.27 1.1.14 2.036-.384 2.79C27.513 31.973 24.351 32 21.678 32H21.5c-3.018-.001-5.488-1.1-7.473-1.983-.997-.444-2.301-.993-3.29-1.011a.75.75 0 01-.737-.75v-13.36c0-.2.08-.392.222-.533 2.476-2.447 3.54-5.037 5.57-7.07.925-.926 1.262-2.327 1.587-3.68C17.657 2.455 18.24 0 19.5 0 21 0 24 .5 24 5.09z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});