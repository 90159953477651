define("ember-svg-jar/inlined/font-awesome-star-o", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M29.2 11.528l-8.117-1.183-3.627-7.355c-.65-1.312-2.534-1.328-3.19 0l-3.627 7.355-8.117 1.183c-1.455.212-2.039 2.006-.983 3.034l5.872 5.722-1.389 8.083c-.25 1.461 1.29 2.556 2.578 1.873l7.261-3.817 7.261 3.817c1.29.677 2.828-.412 2.578-1.873l-1.389-8.083 5.872-5.722c1.056-1.028.473-2.822-.983-3.034zm-7.75 7.823l1.317 7.689-6.906-3.628-6.905 3.628 1.316-7.69-5.589-5.444 7.723-1.122 3.455-7 3.456 7 7.722 1.122-5.589 5.445z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});